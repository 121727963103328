export default defineNuxtRouteMiddleware(async () => {
  const app = useNuxtApp();

  if (app.$user.session.isAuthorized) {
    console.log('return');
    return;
  }

  try {
    console.log('before fetch');
    await app.$user.fetchAndStoreCurrentUser();
    console.log('after fetch');
  } catch (e) {
    console.error(e);
  }
});
